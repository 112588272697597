import { Component, Inject, Input, Output, output } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [],
  providers: [
    { provide: MAT_SNACK_BAR_DATA, useValue: {} }
  ],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss'
})
export class ErrorComponent {
  @Input() errorTitle: string = 'Error';
  @Input() errorMessage: string = 'Sorry something wrong happened, please try again.';
  @Input() showError: boolean = true;
  close = output<boolean>();

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  onClose() {
    this.close.emit(true);
  }
}
